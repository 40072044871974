import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import Swal from "sweetalert2";
import {
  getLegajoByData,
  getAllLegajoActivos,
  getAllLegajoConfirmados,
  LegajoHoraExtra,
  UpdateObs
} from "../../api/marcacionApi";
import { getAllUsuario } from "../../api/usuarioApi";
import { getAllSignosVitales } from "../../api/pacienteApi";
import { exportToExcel } from "react-json-to-excel";
// BS
/// Scroll
import moment from "moment";
import Button from "react-bootstrap/Button";
import ModalDescargaSesiones from "../components/sis/modalreportes/ModalDescargaSesiones";
import ModalHoraExtra from "../components/sis/modalsesiones/Agregarhoraextra";
import ModalObs from "../components/sis/modalsesiones/ModalObs";
// Page titie

const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
        legajoObject: state.legajoObject,
      };
    case "SHOW2":
      return {
        isShow2: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
        legajoObject: state.legajoObject,
      };
    case "SHOW3":
      return {
        isShow3: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
        legajoObject: state.legajoObject,
      };
    case "END":
      return { ...curShow, isShow: false, isShow2: false, isShow3: false };
  }
};

const Sesiones = () => {

  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    legajoObject: null,
    onReturnValue: null,
    typeModal: null,
    x: 0,
  });
  const [List, setList] = useState();
  const [Cliente] = useState("Sesiones");
  const [listMarcacion, setListMarcacion] = useState([]);
  const [alllist, setalllist] = useState([])
  const [signosvitales, setSignosVitales] = useState([])
  const [Legajoactivolist, setLegajoactivo] = useState([]);
  const [FI, setFI] = useState("");
  const [FF, setFF] = useState("");
  const [ItemRow, setItemRow] = useState();
  const onLoadValuesMarcacion = useCallback(async () => {
    try {
      Swal.fire('Cargando')
      Swal.showLoading()
      const listResult = await getAllLegajoConfirmados();
      const legajoactivo = await getAllLegajoActivos();
      const svlist = await getAllSignosVitales();

      setListMarcacion(listResult);
      setSignosVitales(svlist);
      setalllist(listResult)
      setLegajoactivo([Legajoactivolist, ...legajoactivo]);
      Swal.close();
    } catch (err) {
      console.log(err);
    }
  });

  useEffect(() => {
    onLoadValuesMarcacion();
  }, []);
  const showModalSesiones = async () => {
    const resultUsuario = await getAllUsuario();
    const resultMapeado = resultUsuario.map((item) => {
      return { label: item.Nombre + " " + item.subtipo, value: item.IdUsuario };
    });
    const newList = [{ label: "TODOS", value: "TODOS" }, ...resultMapeado];
    setList(newList)
    dispatchReducer({ type: "SHOW" });
  };
  const showModalHoraExtra = (data) => {
    setItemRow(data);
    dispatchReducer({ type: "SHOW2" });
  };
  const buscarmarcacion = () => {
    let filtro = alllist.filter(item => item.Fecha >= FI && item.FechaSalida <= FF
    );
    FF ? setListMarcacion(filtro) : setListMarcacion(alllist)
  };
  const showModalObs = (data) => {
    setItemRow(data);
    dispatchReducer({ type: "SHOW3" });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const GenerarReporte = async (data) => {
    console.log(data)
    dispatchReducer({ type: "END" });
    try {
      let idUsuario = data.ID
      const listResult = await getLegajoByData({ ...data, idUsuario });
      exportToExcel(listResult, "Reporte De Marcacion");
    } catch (err) {
      console.log(err);
    }
  };
  const CalculateHours = (
    fechaBegin,
    fechaEnd,
    tarifa,
    honorario,
    HoraEntrada,
    HoraSalida
  ) => {
    var fecha1 = moment(fechaBegin + " " + HoraEntrada, "YYYY-MM-DD HH:mm:ss");
    var fecha2 = moment(fechaEnd + " " + HoraSalida, "YYYY-MM-DD HH:mm:ss");

    var diff = fecha2.diff(fecha1, "m"); // Diff in hours
    return {
      canthoras: Math.round(diff / 60),
      totalHonorario: Math.round((honorario * diff) / 60),
      totalTarifa: Math.round((tarifa * diff) / 60),
    };
  };
  const convertToMinutes = function (timeString) {
    var hms = timeString.split(':');
    return Math.ceil(parseInt(hms[1]) + parseInt(hms[0]) * 60)
  }
  const FuncionHoraExtra = async (data, data2) => {
    dispatchReducer({ type: "END" });
    try {
      if (data.EstaConfirmado == 1) {
        const horascalculadas = CalculateHours(
          data2.Fecha,
          data2.FechaSalida,
          data2.CostoHoraEmpresa,
          data2.CostoHoraProfesional,
          data2.HoraEntrada,
          data2.HoraSalida
        );
        let horaextracalculada = 0, horastrabajadas = 0;
        if (horascalculadas.canthoras > data2.TotalHoraMaximo) {
          horastrabajadas = horascalculadas.canthoras - data2.TotalHoraMaximo;
          if (horastrabajadas > data.horashoraextra) {
            horaextracalculada = data.horashoraextra
          }
          else {
            horaextracalculada = horastrabajadas
          }
          let horaextra, cantidadhoraextra, IdLegajoEnfermeria, horaextramaximo;
          horaextra = data.horaextra
          cantidadhoraextra = horaextracalculada
          IdLegajoEnfermeria = data.IDLEGAJO
          horaextramaximo = (convertToMinutes(data.horashoraextra) / 60)
          if (horaextra === "False") {
            horaextramaximo = null
          }
          const Guardarhoraextra = await LegajoHoraExtra({ horaextra, cantidadhoraextra, IdLegajoEnfermeria, horaextramaximo })
          if (Guardarhoraextra) {
            Swal.fire({
              title: "Hora Extra Cargado",
              html: `Se cargo correctalmente`,
              icon: "success",
            });
            onLoadValuesMarcacion()

          }
        } else {
          Swal.fire({
            title: "Requisitos minimos para Hora Extra ",
            html: `Invalidos`,
            icon: "warning",
          });
        }
      } else {
        let horaextra = data.horaextra
        let cantidadhoraextra = null
        let IdLegajoEnfermeria = data.IDLEGAJO
        let horaextramaximo = (convertToMinutes(data.horashoraextra) / 60)
        if (horaextra === "False") {
          horaextramaximo = null
          cantidadhoraextra = null
        }
        const resultConfirm = await LegajoHoraExtra({ horaextra, cantidadhoraextra, IdLegajoEnfermeria, horaextramaximo });
        if (resultConfirm) {
          Swal.fire({
            title: "Hora Extra Cargado",
            html: `Se cargo correctalmente`,
            icon: "success",
          });
          onLoadValuesMarcacion()

        }
      }
    } catch (err) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };
  const FuncionOBS = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      const GuardarObs = await UpdateObs(data)

      if (GuardarObs) {
        Swal.fire({
          title: "Obs. Cargado",
          html: `Se cargo correctalmente`,
          icon: "success",
        });
      }
      else {
        Swal.fire({
          title: "No se guardo la Obs.",
          html: `Error`,
          icon: "warning",
        });
      }
      onLoadValuesMarcacion()
    } catch (err) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };
  return (
    <Fragment>
      {/* <Ext /> */}
      <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Sesiones Activas</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive recentOrderTable">
              <table className="table verticle-middle table-responsive-md">
                <thead>
                  <tr>
                    <th scope="col">Usuario</th>
                    <th scope="col">Fecha Entrada</th>
                    <th scope="col">Hora Entrada</th>
                    <th scope="col">ubicacion</th>
                    <th scope="col">Guardia Hs.</th>
                    <th scope="col">Horas Extra Maximo</th>
                    <th scope="col">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {Legajoactivolist.slice(1).map((item, index2) => {
                    return (
                      <tr key={index2}>
                        <td>{item.Usuario}</td>
                        <td>{item.Fecha}</td>
                        <td>{item.HoraEntrada}</td>
                        <td
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}
                        >
                          <a
                            href={
                              "https://www.google.com/maps/search/?api=1&query=" +
                              item.Latitud +
                              "," +
                              item.Longitud
                            }
                            target="_blank"
                          >
                            <div>{item.Paciente}</div>
                          </a>
                        </td>
                        <td>{item.TotalHoraMaximo}</td>
                        <td>{item.horaextramaximo}</td>
                        <td>
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => showModalHoraExtra(item)}
                          >
                            Hora Extra
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Signos Vitales</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive recentOrderTable">
              <table className="table verticle-middle table-responsive-md">
                <thead>
                  <tr>
                    <th scope="col">Cuenta</th>
                    <th scope="col">Usuario</th>
                    <th scope="col">Fecha y Hora</th>
                    <th scope="col">Temp.</th>
                    <th scope="col">SATO2</th>
                    <th scope="col">P.A.</th>
                    <th scope="col">F.C.</th>
                    <th scope="col">F.R.</th>
                    <th scope="col">HGT</th>
                  </tr>
                </thead>
                <tbody>
                  {signosvitales.slice(0, 20).map((item, index2) => {

                    let tempcolor
                    let satcolor
                    let pacolor
                    let fccolor
                    let frcolor
                    let sis
                    let hgtcolor = "black"
                    sis = item.pa.split("/");
                    let nsis = Number(sis[0])
                    //temp
                    if (item.temperatura >= 36.1 && item.temperatura <= 37.2) {
                      tempcolor = "green"
                    } else if (item.temperatura > 37.2 && item.temperatura <= 38.2 || item.temperatura < 36.1 && item.temperatura > 1) {
                      tempcolor = "orange"
                    } else if (item.temperatura > 38.2) {
                      tempcolor = "red"
                    }

                    //SatO2

                    if (item.sato2 >= 95) {
                      satcolor = "green"
                    } else if (item.sato2 < 95 && item.sato2 >= 90) {
                      satcolor = "orange"
                    } else if (item.sato2 < 90 && item.sato2 > 1) {
                      satcolor = "red"
                    }

                    //PA
                    if (nsis <= 120 && nsis >= 90) {
                      pacolor = "green"
                    } else if (nsis < 140 && nsis > 120) {
                      pacolor = "orange"
                    } else if (nsis >= 140) {
                      pacolor = "red"
                    } else if (nsis <= 90 && nsis > 1) {
                      pacolor = "orange"
                    } else if (nsis == 0) {
                      pacolor = "black"
                    } else {
                      pacolor = "black"
                    }

                    //FC
                    if (item.fc < 1) {
                      fccolor = "black"
                    }
                    else if (item.fc <= 100 && item.fc >= 60) {
                      fccolor = "green"
                    } else if (item.fc > 100 && item.fc <= 60) {
                      fccolor = "orange"
                    }

                    if (item.fr < 1) {
                      frcolor = "black"
                    }
                    else if (item.fr <= 20 && item.fr >= 12) {
                      frcolor = "green"
                    } else if (item.fr > 20 || item.fr < 12) {
                      frcolor = "orange"
                    }


                    if (item.hgt < 1) {
                      hgtcolor = "black"
                    }
                    else if (item.hgt > 180) {
                      hgtcolor = "red"
                    } else if (item.hgt > 130) {
                      hgtcolor = "orange"
                    } else if (item.hgt > 79) {
                      hgtcolor = "green"
                    }
                    else if (item.hgt > 60) {
                      hgtcolor = "orange"
                    }
                    else if (item.hgt > 1) {
                      hgtcolor = "red"
                    }

                    return (
                      <tr key={index2}>
                        <td >{item.usuariocreacion}</td>
                        <td>{item.NombrePaciente}</td>
                        <td>{item.fechacreacion}</td>
                        <td style={{color:`${tempcolor}`}}>{item.temperatura} ˚C</td>
                        <td style={{color:`${satcolor}`}}>{item.sato2}%</td>
                        <td style={{color:`${pacolor}`}}>{item.pa} mm Hg</td>
                        <td style={{color:`${frcolor}`}}>{item.fc} LPM</td>
                        <td style={{color:`${frcolor}`}}>{item.fr} RPM</td>
                        <td style={{color:`${hgtcolor}`}}>{item.hgt} mmdl</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Ultimas Sesiones</h4>
            <div className="row">
              <label className="col-form-label">
                Fecha Entrada

              </label>
              <input
                type="date"
                className="form-control"
                required
                placeholder="Inicio"
                name="fechaInicio"
                onChange={(e) => {
                  setFI(e.target.value)
                }}
              />
              <label className="col-form-label">
                Fecha Salida
              </label>
              <input
                type="date"
                className="form-control"
                required
                placeholder="Fin"
                name="fechaInicio"
                onChange={(e) => {
                  setFF(e.target.value)
                }}
              />
              <Button variant="primary" onClick={buscarmarcacion}>
                filtrar
              </Button>
            </div>



            <Button variant="primary" onClick={showModalSesiones}>
              Descarga Marcaciones
            </Button>
            {reducer.isShow && (
              <ModalDescargaSesiones
                estado={reducer.isShow}
                onCloseModal={onCloseModal}
                onReturnValue={GenerarReporte}
                Lista={List}
                ClienteOCuenta={Cliente}
              />
            )}
          </div>
          <div className="card-body">
            <div className="table-responsive recentOrderTable">
              <table className="table verticle-middle table-responsive-md">
                <thead>
                  <tr>
                    <th scope="col">Usuario</th>
                    <th style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }} scope="col">Fecha Entrada</th>
                    <th style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }} scope="col">Hora Entrada</th>
                    <th scope="col">ubicacion</th>
                    <th style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }} scope="col">Fecha Salida</th>
                    <th style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }} scope="col">Hora Salida</th>
                    <th style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }} scope="col">Ubicacion Salida</th>
                    <th style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }} scope="col">Total Horas</th>
                    <th style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }} scope="col">Horas Extra</th>
                    <th style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }} scope="col">Honorario total</th>
                    <th scope="col">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {listMarcacion.slice(0, 50).map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.Usuario}</td>
                        <td>{item.Fecha}</td>
                        <td>{item.HoraEntrada}</td>
                        <td
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}
                        >
                          <a
                            href={
                              "https://www.google.com/maps/search/?api=1&query=" +
                              item.Latitud +
                              "," +
                              item.Longitud
                            }
                            target="_blank"
                          >
                            <div>{item.Paciente}</div>
                          </a>
                        </td>
                        <td>{item.FechaSalida}</td>
                        <td>{item.HoraSalida}</td>
                        <td
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}
                        >
                          <a
                            href={
                              "https://www.google.com/maps/search/?api=1&query=" +
                              item.latsalida +
                              "," +
                              item.longsalida
                            }
                            target="_blank"
                          >
                            <div>{item.Paciente}</div>
                          </a>
                        </td>
                        <td>{item.horascalculadas}</td>
                        <td>{item.horashoraextra}</td>
                        <td>{Math.round((item.horascalculadas * item.CostoHoraProfesional) + (item.horashoraextra * item.CostoHoraProfesional))}</td>
                        <td>
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => showModalHoraExtra(item)}
                          >
                            Hora Extra
                          </Button>
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => showModalObs(item)}
                          >
                            Obs.
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {reducer.isShow2 && (
        <ModalHoraExtra
          estado={reducer.isShow2}
          onReturnValue={FuncionHoraExtra}
          onCloseModal={onCloseModal}
          item={ItemRow}
        />
      )}
      {reducer.isShow3 && (
        <ModalObs
          estado={reducer.isShow3}
          onReturnValue={FuncionOBS}
          onCloseModal={onCloseModal}
          item={ItemRow}
        />
      )}
    </Fragment>
  );
};

export default Sesiones;
